<template>
  <div>
    <table-component
      ref="tableComponent"
      :model="model"
      :permissions="permissions"
      @clearFilter="clearFilter"
    >
      <template v-slot:actions>
        <b-button
          :variant="onlySellersShowed ? 'primary' : 'outline-primary'"
          @click="onlySellers($event)"
        >
          Ver solo vendedores
        </b-button>

        <b-button
          :variant="documentationPendingShowed ? 'primary' : 'outline-primary'"
          @click="onlyPendingDocumentation($event)"
        >
          Documentación pendiente de revisión
        </b-button>
      </template>
    </table-component>
  </div>
</template>

<script>
import TableComponent from '@/dc-it/components/TableComponent.vue'
import Customer from '@/dc-it/models/Customer'
import { BButton } from 'bootstrap-vue'

export default {
  name: 'CustomersView',
  components: { TableComponent, BButton },
  data() {
    return {
      model: Customer,
      permissions: {
        create: false,
        edit: false,
        delete: true,
        filter: true,
      },
      onlySellersShowed: false,
      documentationPendingShowed: false,
    }
  },
  methods: {
    clearFilter() {
      if (this.onlySellersShowed) {
        this.onlySellers()
      }
    },
    onlySellers() {
      if (this.onlySellersShowed) {
        this.onlySellersShowed = false
        this.$refs.tableComponent.filterFields = []
        this.$refs.tableComponent.clearFilter()
      } else {
        this.$refs.tableComponent.filterFields = [
          {
            field: 'is_seller',
            value: 1,
          },
        ]
        this.$refs.tableComponent.applyFilter()
        this.onlySellersShowed = true
      }
    },
    onlyPendingDocumentation() {
      if (this.documentationPendingShowed) {
        this.documentationPendingShowed = false
        this.$refs.tableComponent.filterFields = []
        this.$refs.tableComponent.clearFilter()
      } else {
        this.$refs.tableComponent.filterFields = [
          {
            field: 'documentation_status',
            value: 1,
          },
        ]
        this.$refs.tableComponent.applyFilter()
        this.documentationPendingShowed = true
      }
    },
  },
}
</script>

<style scoped>

</style>
